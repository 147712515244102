<template>
  <el-container class="auth">
    <el-main>
      <div class="auth__form">
        <h1 class="form__title">
          Авторизация
        </h1>
        <el-form :model="form" :rules="rules" ref="form">
          <el-form-item prop="login" label="Логин">
            <el-input id="login" v-model="form.login" />
          </el-form-item>
          <el-form-item prop="password" label="Пароль">
            <el-input id="password" v-model="form.password" type="password" />
          </el-form-item>
          <el-form-item>
            <el-button id="submit" :disabled="isDisabled" type="primary" native-type="submit" @click="submit">
              Войти
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-main>
    <el-footer class="auth__footer">
      &copy; GoodLine 2020
    </el-footer>
  </el-container>
</template>
<script>
import { mapActions } from "vuex";
import { required } from "@/core/helpers/validators";
export default {
  name: "Auth",
  data() {
    return {
      form: {
        login: "",
        password: "",
      },
      rules: {
        login: [required()],
        password: [required()],
      },
    };
  },
  computed: {
    isDisabled() {
      return !(this.form.login.length > 0 && this.form.password.length > 0);
    },
  },
  methods: {
    ...mapActions("auth", { getToken: "GET_TOKEN" }),
    submit(evt) {
      evt.preventDefault();

      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.getToken(this.form)
            .then(() => {
              this.$router.push({ path: this.$route.params.redirect || "/"});
            })
            .catch(({ response }) => {
              this.$message({
                message: response.data.error,
                type: "error",
              });
            });
        }
        return valid;
      });
    },
  },
};
</script>
<style>
.auth {
  position: relative;
  background-color: #e6ebf5;
  height: 100vh;
}
.auth__form {
  position: absolute;
  top: calc(50% - 60px);
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 380px;
}
.auth .form__title {
  font-size: 20px;
}
.auth__footer {
  bottom: 0;
  background-color: #fff;
  line-height: 60px;
  text-align: center;
}
</style>
